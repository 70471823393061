<template>
  <div class="formulario">
    <h1>Receba nossas novidades!</h1>
    <form class="contact-form" @submit="sendEmail">
      <label for="name">NOME:</label>
      <input type="text" id="nome" name="name" />
      <label for="lname">EMAIL:</label>
      <input type="text" id="email" name="email" />
      <input id="botao" type="submit" value="Enviar" />
    </form>
  </div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  methods: {
    sendEmail: (e) => {
      emailjs
        .sendForm("MATURITÉ", "Contato", e.target, "user_4gFwr3wfKlDfP8tpS33H3")
        .then(
          (result) => {
            console.log("SUCCESS!", result.status, result.text);
            window.alert("Dados Enviados com Sucesso");
          },
          (error) => {
            console.log("FAILED...", error);
          },
        );
    },
  },
};
</script>

<style>
input {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body {
  margin: 0;
  padding: 0;
}
.formulario {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  margin: 0;
  padding: 0;
  background-color: black;
}
.formulario h1 {
  color: var(--orange-color);
  /*font-size: 40px;*/
  font-family: var(--font-title);
}
.formulario form {
  width: 23.44vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: black;
  border: 3px solid var(--orange-color);
  padding: 1.3021vw;
}
@media only screen and (max-width: 700px){
  .formulario form{
    width: 80vw;
  }
}
@media only screen and (max-width: 400px){
  .formulario h1{
    font-size: 20px;
  }
}
.formulario form label {
  color: var(--orange-color);
  font-family: var(--font-title);
  /*font-size: 1.042vw;*/
}
.formulario form input {
  background-color: transparent;
  border-style: solid;
  border-color: var(--orange-color);
  margin: 0.2604vw 0;
  padding: 0.5208vw;
  color: var(--orange-color);
  font-family: var(--font-title);
  text-transform: uppercase;
  border-width: 3px;
  /* font-size: 20px; */
}

#botao {
  width: 150px;
  justify-self: flex-end;
}
#botao:hover {
  border-color: white;
  color: white;
  cursor: pointer;
}
</style>
